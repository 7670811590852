"use client";
import { signIn } from "next-auth/react";
import { usePostHog } from "posthog-js/react";
import React, { useEffect } from "react";

const LoginPage = () => {
  const posthog = usePostHog();

  useEffect(() => {
    posthog?.capture("clicked_log_in");

    signIn("auth0", undefined, {
      prompt: "login",
    });
  }, []);

  return <></>;
};

export default LoginPage;
